<script lang="ts" setup>
import type { ComponentBlockTextImageFragment } from "~~/types/graphql";

import {
  Enum_Componentblocktextimage_Variation,
  Enum_Componentblocktextimage_Flipped,
} from "~~/types/graphql";

const props = defineProps<{
  TextImageFlipped: ComponentBlockTextImageFragment["TextImageFlipped"];
  TextImageHasCta: ComponentBlockTextImageFragment["TextImageHasCta"];
  TextImageHeadline: ComponentBlockTextImageFragment["TextImageHeadline"];
  TextImageImage: ComponentBlockTextImageFragment["TextImageImage"];
  TextImageImageEagerLoading: ComponentBlockTextImageFragment["TextImageImageEagerLoading"];
  TextImageImageFetchPriority: ComponentBlockTextImageFragment["TextImageImageFetchPriority"];
  TextImageLinkAnchor: ComponentBlockTextImageFragment["TextImageLinkAnchor"];
  TextImageLink: ComponentBlockTextImageFragment["TextImageLink"];
  TextImageLinkExternal: ComponentBlockTextImageFragment["TextImageLinkExternal"];
  TextImageLinkTarget: ComponentBlockTextImageFragment["TextImageLinkTarget"];
  TextImageSubheadline: ComponentBlockTextImageFragment["TextImageSubheadline"];
  TextImageText: ComponentBlockTextImageFragment["TextImageText"];
  TextImageVariation: Enum_Componentblocktextimage_Variation;
}>();

function link() {
  let linkUrl = false;

  if (props.TextImageLinkTarget === "external") {
    linkUrl = props.TextImageLinkExternal || false;
  }
  // Default option 'page'
  else {
    linkUrl = props.TextImageLink.data?.attributes?.Slug || false;

    if (linkUrl && props.TextImageLinkAnchor) {
      linkUrl = linkUrl + "#" + props.TextImageLinkAnchor;
    }
  }

  return linkUrl;
}
</script>
<template>
  <layout-block
    :has-cta="props.TextImageHasCta"
    :target-blank="props.TextImageLinkTarget === 'external' ? true : false"
    :cta-button-type="
      TextImageVariation === Enum_Componentblocktextimage_Variation.Dark
        ? 'primary'
        : 'secondary'
    "
    :link="link()"
    :title="props.TextImageSubheadline ?? undefined"
    :class="{
      'bg-integer-black text-integer-white':
        props.TextImageVariation ===
        Enum_Componentblocktextimage_Variation.Dark,
      'bg-integer-white text-integer-black':
        props.TextImageVariation ===
        Enum_Componentblocktextimage_Variation.Light,
    }"
    class="text-image"
  >
    <div class="grid gap-10 md:grid-cols-12">
      <div
        class="md:row-start-1"
        :class="{
          'md:col-start-7 md:col-end-12':
            props.TextImageFlipped ===
              Enum_Componentblocktextimage_Flipped.Right ||
            !props.TextImageFlipped,
          'md:col-start-1 md:col-end-6':
            props.TextImageFlipped ===
            Enum_Componentblocktextimage_Flipped.Left,
        }"
      >
        <base-image
          v-bind="props.TextImageImage.data?.attributes"
          sizes="(min-width: 768px) 40vw, (max-width: 767px) 100vw"
          :eager-loading="props.TextImageImageEagerLoading ? true : false"
          :fetch-priority="props.TextImageImageFetchPriority ? true : false"
        />
      </div>
      <div
        class="grid content-end md:row-start-1"
        :class="{
          'md:col-start-1 md:col-end-6':
            props.TextImageFlipped ===
              Enum_Componentblocktextimage_Flipped.Right ||
            !props.TextImageFlipped,
          'md:col-start-7 md:col-end-12':
            props.TextImageFlipped ===
            Enum_Componentblocktextimage_Flipped.Left,
        }"
      >
        <h2
          v-if="props.TextImageHeadline"
          class="hyphens-auto font-pixel text-h2-s md:text-h2"
        >
          {{ props.TextImageHeadline }}
        </h2>
        <base-text
          v-if="props.TextImageText"
          :Text="props.TextImageText"
          class="mt-5.6"
        />
      </div>
    </div>
  </layout-block>
</template>
